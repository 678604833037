<template>
  <main
    class="color-primary h-100 page_bg_grey blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <p class="main-title weight-700">
      {{ $t('Shaniv products') }}
    </p>

    <div class="main-form">
      <div v-if="chosenProduct.EXTPART">
        <div class="bdbr-1 px-10 mb-10">
          <p class="sub-title weight-700 mt-2">
            {{ $t('Product Details') }}:
            {{ chosenProduct.PARTDES }}
          </p>
          <div class="form-block">
            <div class="form-item">
              <label>{{ $t('EXTPART product code') }}</label>
              <div class="mt-2 mb-7 icon-input">
                <input
                  type="text"
                  class="input"
                  v-model="chosenProduct.EXTPART"
                  disabled
                />
              </div>
            </div>

            <div class="form-item">
              <label>{{ $t('PARTNAME') }}</label>
              <div class="mt-2 mb-7 icon-input">
                <input
                  type="text"
                  class="input"
                  v-model="chosenProduct.PARTNAME"
                  disabled
                />
              </div>
            </div>

            <div class="form-item">
              <label>{{ $t('BARCODE barcode') }}</label>
              <div class="mt-2 mb-7 icon-input">
                <input
                  type="text"
                  class="input"
                  v-model="chosenProduct.SHAN_BARCODE"
                  disabled
                />
              </div>
            </div>

            <div class="form-item">
              <label>{{ $t('PRICE price') }}</label>
              <div class="mt-2 mb-7 icon-input">
                <input
                  type="text"
                  class="input"
                  v-model="chosenProduct.PRICE"
                  disabled
                />
              </div>
            </div>

            <div class="form-item">
              <label>{{ $t('price including vat') }}</label>
              <div class="mt-2 mb-7 icon-input">
                <input
                  type="text"
                  class="input"
                  v-model="chosenProduct.VATPRICE"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div class="bdbr-1 px-10 mb-10">
          <p class="sub-title weight-700 mt-2">
            {{ $t('Product settings') }}
          </p>

          <div class="form-block">
            <div class="form-item">
              <label>{{ $t('Product name: Hebrew') }}</label>
              <div class="mt-2 mb-7 icon-input">
                <input type="text" class="input" v-model="chosenProduct.he_name" />
                <img
                  src="/assets/img/icons/tranlate.png"
                  alt=""
                  class="translate-img"
                />
              </div>
            </div>

            <div class="form-item">
              <label>{{ $t('Product name: English') }}</label>
              <div class="mt-2 mb-7 icon-input">
                <input type="text" class="input" v-model="chosenProduct.en_name" />
                <img
                  src="/assets/img/icons/tranlate.png"
                  alt=""
                  class="translate-img"
                />
              </div>
            </div>

            <div class="form-item">
              <label>{{ $t('Product name: Arabic') }}</label>
              <div class="mt-2 mb-7 icon-input">
                <input type="text" class="input" v-model="chosenProduct.ar_name" />
                <img
                  src="/assets/img/icons/tranlate.png"
                  alt=""
                  class="translate-img"
                />
              </div>
            </div>

            <div class="form-item upload-form">
              <div class="p-relative mx-15">
                <input
                  class="tour-passport__file w-100"
                  type="file"
                  @change="handleImage"
                  accept="image/*"
                  ref="uplaod_file"
                  multiple="multiple"
                />
                <button class="primary-btn upload-btn">
                  {{ $t('Select an image') }}
                </button>
              </div>
              <div class="upload-part bg-white bdbr-2 p-relative">
                <img
                  :src="chosenProduct.image ? chosenProduct.image : '/assets/img/icons/upload.png'"
                  alt=""
                  v-if="!isPreview"
                />
                <img
                  :src="previewImage"
                  alt=""
                  v-show="isPreview"
                  v-else
                />
              </div>
            </div>

            <div class="form-item w-100">
              <label>{{ $t('product description: hebrew') }} he</label>
              <textarea
                rows="2"
                class="w-100 bg-white mt-2 mb-7 px-5 bdbr-2"
                v-model="chosenProduct.he_description"
              >
              </textarea>
            </div>

            <div class="form-item w-100">
              <label>{{ $t('product description: english') }} en</label>
              <textarea
                rows="2"
                class="w-100 bg-white mt-2 mb-7 px-5 bdbr-2"
                v-model="chosenProduct.en_description"
              >
              </textarea>
            </div>

            <div class="form-item w-100">
              <label>{{ $t('product description: arabic') }} ar</label>
              <textarea
                rows="2"
                class="w-100 bg-white mt-2 mb-7 px-5 bdbr-2"
                v-model="chosenProduct.ar_description"
              >
              </textarea>
            </div>

            <div class="form-item w-50">
              <div class="d-flex align-items-center mb-5">
                <v-checkbox
                      color="#0D3856"
                      class="cus-checkbox"
                      v-model="chosenProduct.set_package_quantity_manually"
                      id="set_package_quantity_manually"
                ></v-checkbox>
                <label for="set_package_quantity_manually" class="px-5">{{ $t('Set package quantity') }}</label>
              </div>
              <div v-show="chosenProduct.set_package_quantity_manually" class="px-10">
                <div class="form-item w-100">
                  <label>{{ $t('Package quantity') }}</label>
                  <input type="number" class="input mt-2 mb-7"  v-model="chosenProduct.manual_package_quantity" />
                </div>
              </div>
            </div>

            <div class="form-item w-50">
              <div class="d-flex align-items-center mb-5">
                <v-checkbox
                      color="#0D3856"
                      class="cus-checkbox"
                      v-model="chosenProduct.set_pallet_quantity_manually"
                      id="set_pallet_quantity_manually"
                ></v-checkbox>
                <label for="set_pallet_quantity_manually" class="px-5">{{ $t('Set pallet quantity') }}</label>
              </div>
              <div v-show="chosenProduct.set_pallet_quantity_manually" class="px-10">
                <div class="form-item w-100">
                  <label>{{ $t('Pallet quantity') }}</label>
                  <input type="number" class="input mt-2 mb-7"  v-model="chosenProduct.manual_pallet_quantity" />
                </div>
              </div>
            </div>

            <div class="d-flex bottom-btns">
              <button class="primary-btn" @click="save()">
                {{ $t('Save') }}
              </button>
            </div>
          </div>
        </div>

        <div class="bdbr-1 px-10 d-flex pb-15 mb-5">
          <p class="sub-title weight-700 mt-2">
            {{ $t('Tags') }}
          </p>

          <div class="smanage-tag-block px-10">
            <div v-for="tag in shanivTags" :key="tag.id">
              <div
                class="tag-btn pointer"
                @click="tagClick(tag.id)"
                v-bind:class="{ 'tag-btn_active': chosenProduct.tags.includes(tag.id) }"
                :ref="'tag' + tag.id"
              >
                {{ tag.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-content-part">
        <div class="d-flex justify-content-between">
          <div class="search-bar">
            <p class="search-label">{{ $t('Search') }}</p>
            <v-text-field
              v-model="search"
              single-line
              hide-details
              class="search-form"
              @input="debounceSearch"
            ></v-text-field>
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :items="products_data.data"
          class="cus-table editable-table main-table striped-table cursor-table"
          item-key="id"
          :loading="loading"
          :options.sync="options"
          :server-items-length="products_data.total"
          :footer-props="{
            'items-per-page-options': itemsPerPageOptions
          }"
          @click:row="rowClick"
        >
          <template v-slot:[`item.BALANCE_original`]="{ item }">
            <div v-if="item.BALANCE_original == null || item.BALANCE_original <= 0" class="color-red4">
              {{ typeof item.BALANCE_original == 'number' ? item.BALANCE_original : '-' }}
            </div>
            <div v-else>
              {{ item.BALANCE_original }}
            </div>
          </template>
        </v-data-table>
      </div>
      <section class="bottom-actions">
        <button class="primary-btn bg-blue" @click="openImportSastechQuantityModal">
          {{ $t('Import sastech quantity') }}
        </button>
      </section>
    </div>
    <modal
      :width="550"
      :adaptive="true"
      class="importSastechQuantityModal CEModal modal-scroll-bar"
      name="importSastechQuantityModal"
    >
      <div class="modal-content">
        <div class="validation-form-group"
           :class="{ 'form-group--error': excelFileError }">
          <label>{{ $t('Import file') }} *</label>
          <input
            type="file"
            class="input"
            @change="handleExcelFile"
            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            name="excel_file"
          />
          <div class="color-red1 fs12 valide-error-msg" v-if="excelFileError">
            {{ $t('This is a required field.') }}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="primary-btn primary-btn_sm mx-5" @click="importSastechQuantity">
          {{ $t('Import') }}
        </button>
        <button class="primary-btn primary-btn_sm mx-5 bg-2" @click="closeImportSastechQuantityModal">
          {{ $t('Cancel') }}
        </button>
      </div>
    </modal>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>    

<script>
import { mapState, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'CPShanivManagement',
  components: {
    Loading,
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'EXTPART',
          align: 'center',
          sortable: false,
          value: 'EXTPART',
        },
        {
          text: 'PARTNAME',
          align: 'center',
          sortable: false,
          value: 'PARTNAME',
        },
        {
          text: 'PARTDES',
          align: 'center',
          sortable: false,
          value: 'PARTDES',
        },
        {
          text: 'PLNAME',
          align: 'center',
          sortable: false,
          value: 'PLNAME',
        },
        {
          text: 'PRICE',
          align: 'center',
          sortable: false,
          value: 'PRICE',
        },
        {
          // text: this.$t('Material') + ' SPEC2',
          text: 'VATPRICE',
          align: 'center',
          sortable: false,
          value: 'VATPRICE',
        },
        {
          // text: this.$t('Material') + ' SPEC2',
          text: 'BASEPRICE',
          align: 'center',
          sortable: false,
          value: 'BASEPRICE',
        },
        {
          // text: this.$t('Quantity') + ' SPEC13',
          text: 'SHAN_BARCODE',
          align: 'center',
          sortable: false,
          value: 'SHAN_BARCODE',
        },
        {
          text: 'not_active_since',
          align: 'center',
          sortable: false,
          value: 'not_active_since',
        },
        {
          text: 'BALANCE',
          align: 'center',
          sortable: false,
          value: 'BALANCE_original',
        },
      ],
      previewImage: '',
      isPreview: false,
      chosenProduct: {},
      itemsPerPageOptions: [10, 15, 25, 50, 100],
      options: {
        itemsPerPage: 10,
        page: 1
      },
      excelFile: null,
      excelFileError: false
    };
  },
  async created() {
     if (this.products_data.length == 0) {
      await this.getProductsList(1);
    }
    if (this.shanivTags.length === 0) {
      this.getShanivTags();
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.shaniv_management.loading,
      products_data: (state) => state.shaniv_management.products,
      shanivTags: (state) => state.shaniv_management.shanivTags,
    }),
    set_package_quantity_manually() {
      let set_package_quantity_manually = this.chosenProduct.set_package_quantity_manually;
      return set_package_quantity_manually;
    }
  },
  methods: {
    ...mapActions('shaniv_management', {
      getProducts: 'getProducts',
      updateData: 'updateData',
      updateTags: 'updateTags',
      getShanivTags: 'getShanivTags',
      updateProductsQuantityFromExcel: 'updateProductsQuantityFromExcel',
    }),
    //
    importSastechQuantity() {
      if (!this.excelFile) {
        this.excelFileError = true;
        return false;
      }

      this.excelFileError = false;

      let formData = new FormData();

      formData.append('file', this.excelFile);
      formData.append('company', 'sastech');

      this.updateProductsQuantityFromExcel({
        params: formData,
      }).finally(() => {
        this.closeImportSastechQuantityModal();
        this.excelFile = null;
        this.excelFileError = false;
      });
    },
    openImportSastechQuantityModal() {
      this.$modal.show('importSastechQuantityModal');
    },
    closeImportSastechQuantityModal() {
      this.$modal.hide('importSastechQuantityModal');
    },
    rowClick(item) {
      const selectedItem = JSON.parse(JSON.stringify(item));
      for (const key in selectedItem){
        if (selectedItem[key] == 'null') {
          selectedItem[key] = '';
        }
      }
      this.chosenProduct = selectedItem;
    },
    //
    handleImage(e) {
      this.chosenProduct.image = this.$refs.uplaod_file.files[0];
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      this.isPreview = true;
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
    },
    handleExcelFile(e) {
      if (e.target.files.length > 0) {
        this.excelFile = e.target.files[0];
      }
    },
    //
    save() {
      let formData = new FormData();

      formData.append('image', this.chosenProduct.image);
      formData.append('en_name', this.chosenProduct.en_name || '');
      formData.append('he_name', this.chosenProduct.he_name || '');
      formData.append('ar_name', this.chosenProduct.ar_name || '');
      formData.append('he_description', this.chosenProduct.he_description || '');
      formData.append('en_description', this.chosenProduct.en_description || '');
      formData.append('ar_description', this.chosenProduct.ar_description || '');
      formData.append('set_package_quantity_manually', this.chosenProduct.set_package_quantity_manually || '');
      formData.append('manual_package_quantity', this.chosenProduct.manual_package_quantity || '');
      formData.append('set_pallet_quantity_manually', this.chosenProduct.set_pallet_quantity_manually || '');
      formData.append('manual_pallet_quantity', this.chosenProduct.manual_pallet_quantity || '');
      formData.append('tags', JSON.stringify(this.chosenProduct.tags));

      this.updateData({
        id: this.chosenProduct.id,
        params: formData,
      });
    },
    tagClick(id) {
      if (this.$refs['tag'+id][0].classList) {
        if (!this.chosenProduct.tags.includes(id)) {
          this.chosenProduct.tags.push(id);
          this.$refs['tag'+id][0].classList.add("tag-btn_active");
        } else {
          this.removeA(this.chosenProduct.tags, id);
          this.$refs['tag'+id][0].classList.remove("tag-btn_active");
        }
      }
    },
    getProductsList(page) {
      page = +page || +this.options.page;
      this.options.page = page;
      let itemsPerPage = +this.options.itemsPerPage;
      this.getProducts({
        per_page: itemsPerPage, 
        page: page-1,
        search_term: this.search,
      });
    },
    debounceSearch() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.getProductsList(1);
      }, 1000)
    }
  },
  watch: {
    set_package_quantity_manually (newVal, oldVal) {
      if (newVal === true && newVal !== oldVal
        && !this.chosenProduct.manual_package_quantity
        && this.chosenProduct.FSHA_CARTONINLAYER) {

        this.chosenProduct.manual_package_quantity = this.chosenProduct.FSHA_CARTONINLAYER;

      }
    },
    options: {
      handler () {
        this.getProductsList();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.cus-select .vs__selected {
  font-weight: 400;
}

.cursor-table tr:hover {
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
.importSastechQuantityModal {
  .modal-footer {
    padding: 0 40px 30px;
  }
  input[type="file"] {
    line-height: 31px;
  }
}
.bottom-actions {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 35px;
    .primary-btn {
        margin: 0 15px;
        /*width: 190px;*/
        height: 40px;
        font-size: 16px;
    }
}
label {
  font-size: 16px;
}
.main-form {
  margin: 20px 50px 0 50px;
  .smanage-tag-block {
    display: flex;
    flex-wrap: wrap;
    .tag-btn {
      border: 1px solid #03b2ff;
      font-size: 14px;
      font-weight: 700;
      width: 120px;
      height: 26px;
      margin: 15px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tag-btn_active {
      background-color: #03b2ff;
    }
  }
  .form-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .form-item {
      width: calc(25% - 30px);
      .input {
        height: 32px;
      }
      .add-lang_btn {
        height: 32px;
        margin-top: 32px;
      }
    }
    .space-form-item_3 {
      display: none;
    }
    .form-checkbox {
      display: flex;
      align-items: center;
    }
    .upload-form {
      display: flex;
      justify-content: flex-end;
      align-items: baseline;
      margin-bottom: 20px;
      .upload-btn {
        height: 26px;
        font-size: 14px;
        width: 120px;
      }
      .upload-part {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        width: 70px;
        height: 60px;
        background-size: contain;
        background-position: center;
        img {
          width: 45px;
        }
      }
    }
  }
  .translate-img {
    width: 32px;
    height: 32px;
  }
  .bottom-btns {
    margin-bottom: 50px;
    .primary-btn {
      width: 200px;
      height: 40px;
    }
  }
  .table-content-part {
    margin: 20px 0;
  }
}

@media screen and (max-width: 1100px) {
  .main-form {
    .form-block {
      .form-item {
        width: calc(33% - 20px);
      }
      .space-form-item_4 {
        display: none;
      }
      .space-form-item_3 {
        display: block;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .main-form {
    .form-block {
      .form-item {
        width: calc(50% - 20px);
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .main-form {
    margin: 20px;
    .form-block {
      .form-item {
        width: 100%;
      }
    }
  }
}
</style>